<!--<link rel="stylesheet" [href]='sanitizerCss'>-->
<div class="site" [ngClass]="classReference.bodyClass" *ngIf="brand">
  <!--<section class="section section&#45;&#45;header-container">-->
  <!--<div class="container-fluid">-->
  <!--<div class="row justify-content-end">-->
  <!--<div class="col-7">-->
  <!--<div class="promo-banner">-->
  <!--<img src="assets/uploads/home/promo.jpg" alt="" />-->
  <!--<div class="promo-banner__txt">-->
  <!--<img src="assets/uploads/home/promo.gif" alt="" />-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--</section>-->
  <section class="section section--header">
    <header class="header" *ngIf="brand">
      <div *ngIf="brand.slug === 'fiat' || brand.slug === 'fiat-professional'" class="container h-100">
        <div class="row h-100">
          <div class="header__logo-wrap col-sm-6 col-md-2 align-self-center">
            <a [routerLink]="'' | addUrlPrefix" class="header__logo"
               (click)="clickLogo()">
              <img src="assets/img/brands/{{ brand.slug }}/logo.png?v=2" alt="Salon {{ brand.name }} - logo"/>
            </a>
          </div>
          <div class="col-sm-6 col-md-10 text-right align-self-center">

            <ul class="header--nav-list" [ngClass]="{'open-nav-list': showNavListMenu}">
              <div class="nav-list-close-icon" (click)="openOrCloseNavListMenu(false)">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 18L2 2M18 2L2 18" stroke="#FEFFFF" stroke-width="3" stroke-linecap="round"/>
                </svg>
              </div>
              <ng-container *ngIf="lfaUrl !== null">
              <li *ngIf="lfaUrl.configurator !== null">
                <a [href]="lfaUrl.configurator" (click)="sendFloodlight('configurator')" target="_blank" data-adobe="content:shopping-tools:configurator">KONFIGURATOR</a>
              </li>
              <li *ngIf="lfaUrl.testDrive !== null">
                <a [href]="lfaUrl.testDrive" (click)="sendFloodlight('testDrive')" target="_blank" data-adobe="content:contact:test-drive-request">JAZDA TESTOWA</a>
              </li>
              <li *ngIf="lfaUrl.dealers !== null">
                <a [href]="lfaUrl.dealers" (click)="sendFloodlight('dealers')" target="_blank" data-adobe="content:shopping-tools:dealer-locator">DEALERZY</a>
              </li>
              </ng-container>
              <li class="nav-list-sol-faq">
                <span (click)="scrollToFaq()">FAQ</span>
              </li>
              <li>
                <a [routerLink]="'/obserwowane-ogloszenia' | addUrlPrefix"
                   *ngIf="observedOffers.getAllOffers(classReference.dealerCode).length > 0"
                   data-adobe="top-nav:stocklocator:wishlist">
                  ULUBIONE
                  <span style="position: relative; display: inline-block;">
                    <svg fill="none" height="19" viewBox="0 0 21 19" width="21" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.47852 17.0751L9.47743 17.0742C6.75877 14.6431 4.56312 12.6755 3.03837 10.836C1.52151 9.00596 0.75 7.39658 0.75 5.69482C0.75 2.92032 2.94439 0.75 5.775 0.75C7.37914 0.75 8.92829 1.49024 9.93427 2.64607L10.5 3.29607L11.0657 2.64607C12.0717 1.49024 13.6209 0.75 15.225 0.75C18.0556 0.75 20.25 2.92032 20.25 5.69482C20.25 7.39658 19.4785 9.00596 17.9616 10.836C16.4369 12.6755 14.2412 14.6431 11.5226 17.0742L11.5215 17.0751L10.5 17.9921L9.47852 17.0751Z"
                        stroke="#A80C42" stroke-width="1.5"></path>
                    </svg>
                    <span class="favorite-offer-count">
                      {{observedOffers.getAllOffers(classReference.dealerCode).length}}
                    </span>
                  </span>
                </a>
                <a [routerLink]="'/obserwowane-ogloszenia' | addUrlPrefix"
                   *ngIf="observedOffers.getAllOffers(classReference.dealerCode).length === 0">
                  ULUBIONE
                  <span style="position: relative; display: inline-block;">
                    <svg fill="none" height="19" viewBox="0 0 21 19" width="21" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.47852 17.0751L9.47743 17.0742C6.75877 14.6431 4.56312 12.6755 3.03837 10.836C1.52151 9.00596 0.75 7.39658 0.75 5.69482C0.75 2.92032 2.94439 0.75 5.775 0.75C7.37914 0.75 8.92829 1.49024 9.93427 2.64607L10.5 3.29607L11.0657 2.64607C12.0717 1.49024 13.6209 0.75 15.225 0.75C18.0556 0.75 20.25 2.92032 20.25 5.69482C20.25 7.39658 19.4785 9.00596 17.9616 10.836C16.4369 12.6755 14.2412 14.6431 11.5226 17.0742L11.5215 17.0751L10.5 17.9921L9.47852 17.0751Z"
                        stroke="#A80C42" stroke-width="1.5"></path>
                    </svg>
                    <span class="favorite-offer-count">
                      {{observedOffers.getAllOffers(classReference.dealerCode).length}}
                    </span>
                  </span>
                </a>
              </li>
            </ul>
            <div class="compare-icon" [ngClass]="{'compare-icon-active': classReference.bodyClass === 'page compare-page'}">
              <a [routerLink]="'/porownaj-oferty' | addUrlPrefix">
                <div class="compare-icon-arrows">
                  <svg width="26" height="12" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.7217 1.13393L24.8713 6.67667C24.9119 6.71837 24.9443 6.76854 24.9665 6.82412C24.9886 6.87969 25 6.93953 25 7C25 7.06047 24.9886 7.1203 24.9665 7.17588C24.9443 7.23146 24.9119 7.28162 24.8713 7.32333L19.7217 12.8661C19.642 12.9518 19.5339 13 19.4213 13C19.3086 13 19.2005 12.9518 19.1209 12.8661C19.0412 12.7803 18.9964 12.664 18.9964 12.5427C18.9964 12.4215 19.0412 12.3052 19.1209 12.2194L23.5338 7.46189L1.42913 7.4619C1.31532 7.4619 1.20617 7.41323 1.12569 7.32661C1.04521 7.23999 1 7.1225 1 7C1 6.8775 1.04521 6.76001 1.12569 6.67339C1.20617 6.58677 1.31532 6.5381 1.42913 6.5381L23.5338 6.5381L19.1209 1.78058C19.0412 1.69483 18.9964 1.57852 18.9964 1.45725C18.9964 1.33598 19.0412 1.21968 19.1209 1.13393C19.2005 1.04817 19.3086 1 19.4213 1C19.5339 1 19.642 1.04817 19.7217 1.13393Z" fill="#1B1C1B" stroke="#1B1C1B" stroke-width="0.7"/>
                    <path d="M6.27835 12.8661L1.12874 7.32333C1.08806 7.28163 1.05567 7.23146 1.03354 7.17589C1.01141 7.12031 1 7.06047 1 7C0.999998 6.93953 1.01141 6.8797 1.03354 6.82412C1.05567 6.76854 1.08806 6.71838 1.12874 6.67667L6.27835 1.13393C6.35802 1.04817 6.46607 1 6.57874 1C6.69141 1 6.79947 1.04817 6.87914 1.13393C6.95881 1.21968 7.00356 1.33598 7.00356 1.45725C7.00356 1.57852 6.95881 1.69483 6.87914 1.78058L2.46621 6.5381L24.5709 6.5381C24.6847 6.5381 24.7938 6.58677 24.8743 6.67339C24.9548 6.76001 25 6.8775 25 7C25 7.1225 24.9548 7.23999 24.8743 7.32661C24.7938 7.41323 24.6847 7.4619 24.5709 7.4619L2.46621 7.4619L6.87914 12.2194C6.95881 12.3052 7.00356 12.4215 7.00356 12.5427C7.00356 12.664 6.95881 12.7803 6.87914 12.8661C6.79947 12.9518 6.69141 13 6.57874 13C6.46607 13 6.35802 12.9518 6.27835 12.8661Z" fill="#1B1C1B" stroke="#1B1C1B" stroke-width="0.7"/>
                  </svg>
                </div>
              </a>
            </div>

            <div class="hamburger-icon" (click)="openOrCloseNavListMenu(true)"></div>

            <ng-container *ngIf="brand && checkQSS() === 1 && brand.slug !== 'fiat' && brand.slug !== 'fiat-professional'">
             <app-banner [brand]="brand"
                         [bodyClass]="classReference.bodyClass"
                         [bannerPosition]="'header'"
                         *ngIf="checkQSS() === 1">
             </app-banner>
            </ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="brand.slug !== 'fiat' &&  brand.slug !== 'fiat-professional'" class="container h-100">
        <div class="row h-100">
          <div class="header__logo-wrap col-sm-6 col-md-3 order-sm-1 order-2 align-self-center">
            <a [routerLink]="'' | addUrlPrefix" class="header__logo"
               (click)="clickLogo()">
              <img src="assets/img/brands/{{ brand.slug }}/logo.png" alt="Salon {{ brand.name }} - logo"/>
            </a>
          </div>
          <div class="col-sm-6 col-md-9 order-sm-2 text-right order-1">
            <div class="float-right">
              <ul class="nav">
                <li class="nav-item" *ngIf="brand">
                  <a [routerLink]="'/obserwowane-ogloszenia' | addUrlPrefix" class="btn btn--clipboard"
                     *ngIf="observedOffers.getAllOffers(classReference.dealerCode).length > 0"
                     data-adobe="top-nav:stocklocator:wishlist">
                    <i class="icon-star_contour"></i>
                    <span>
                                          {{observedOffers.getAllOffers(classReference.dealerCode).length}}
                      <span class="btn--clipboard__text d-none d-sm-block">OBSERWOWANE</span>
                                      </span>
                  </a>
                  <a class="btn btn--clipboard"
                     *ngIf="observedOffers.getAllOffers(classReference.dealerCode).length === 0">
                    <i class="icon-star_contour"></i>
                    <span>
                                          {{observedOffers.getAllOffers(classReference.dealerCode).length}}
                      <span class="btn--clipboard__text d-none d-sm-block">OBSERWOWANE</span>
                                      </span>
                  </a>
                </li>
                <li class="nav-item">
                  <span id="selectBrandBtn" data-toggle="modal" data-target="#selectBrand"
                        class="btn btn--menu" data-adobe="top-nav:stocklocator:menu">
                      <span class="icon-hamburger d-none d-md-inline-block"></span>
                      <span class="icon-mobile_hamburger d-inline-block d-md-none"></span>
                  </span>
                </li>
              </ul>
            </div>
            <div *ngIf="brand && brand.slug === 'alfa-romeo'" class="headline--title header-headline--title">
              <h1>WIRTUALNY SALON ALFA ROMEO<br />
                <small>AUTA DOSTĘPNE OD RĘKI</small></h1>
            </div>
            <div *ngIf="brand && brand.slug === 'jeep'" class="headline--title header-headline--title">
              <h1>WIRTUALNY SALON JEEP<br />
                <small>AUTA DOSTĘPNE OD RĘKI</small></h1>
            </div>
            <ng-container *ngIf="brand && checkQSS() === 1 && brand.slug !== 'fiat' && brand.slug !== 'fiat-professional'">
              <ng-container *ngIf="bannerCounter.enable">
                <div class="header__banner banner-counter banner-counter-header">
                  <img src="/assets/img/brands/alfa-romeo/banner/banner-620x100.jpg"/>
                  <div class="banner-counter-text" [innerHTML]="bannerCounter.text"></div>
                </div>
              </ng-container>
             <app-banner [brand]="brand"
                         [bodyClass]="classReference.bodyClass"
                         [bannerPosition]="'header'"
                         *ngIf="checkQSS() === 1 && !bannerCounter.enable">
             </app-banner>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="headline--title section-headline--title" *ngIf="brand">
        <div *ngIf="brand && brand.slug === 'alfa-romeo'">
          <h1>WIRTUALNY SALON ALFA ROMEO<br />
            <small>AUTA DOSTĘPNE OD RĘKI</small></h1>
        </div>
        <div *ngIf="brand && brand.slug === 'jeep'">
          <h1>WIRTUALNY SALON JEEP<br />
            <small>AUTA DOSTĘPNE OD RĘKI</small></h1>
        </div>
      </div>
    </header>

    <ng-container *ngIf="brand && (brand.slug === 'fiat' || brand.slug === 'fiat-professional')">
      <app-banner [brand]="brand"
                  [bodyClass]="classReference.bodyClass"
                  [bannerPosition]="'underHeader'"
                  *ngIf="checkQSS() === 1">
      </app-banner>
    </ng-container>

    <ng-container *ngIf="brand && brand.slug !== 'fiat' && brand.slug !== 'fiat-professional'">
      <ng-container *ngIf="bannerCounter.enable">
        <section class="section section-banner mt-5 mt-sm-3 mb-3 banner-counter-section" [ngClass]="{'mt-md-5': brand.slug === 'jeep' || brand.slug === 'alfa-romeo'}">
          <div class="container">
            <div class="row">
              <div class="col-12 banner-counter">
                <img class="mx-auto mw-100"
                     src="/assets/img/brands/alfa-romeo/banner/banner-620x100.jpg"/>
                <div class="banner-counter-text" [innerHTML]="bannerCounter.text"></div>
              </div>
            </div>
          </div>
        </section>
      </ng-container>
      <app-banner [brand]="brand"
                  [bodyClass]="classReference.bodyClass"
                  [bannerPosition]="'section'"
                  *ngIf="checkQSS() === 1 && !bannerCounter.enable">
      </app-banner>
    </ng-container>
  </section>

  <app-page-not-found *ngIf="show404Error"></app-page-not-found>
  <ng-container *ngIf="!show404Error">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </ng-container>
  <app-modals></app-modals>

  <section class="section section--note">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 ml-lg-auto">
          <app-banner-note *ngIf="checkQSS() === 1"></app-banner-note>
<!--          <p *ngIf="brand && brand.slug === 'fiat-professional'">-->
<!--            Pakiet Serwisowy Pełna Obsługa na 3 lata /180 000 km za 1 zł netto dotyczy tylko aut dostawczych, zamówionych online poprzez https://salon.fiatprofessional.pl/ , promocja obowiązuje do 30.09.2024 .Promocją objęte jest pierwszych 50 zamówień online. Licznik na stronie salon.fiatprofessional.pl wyznacza ilość dostępnych samochodów w promocji. Szczegóły pakietu serwisowego Pełna Obsługa u autoryzowanych dealerów Fiata.-->
<!--            <br />-->
<!--            <br />-->
<!--            Pakiet ubezpieczeń OC/AC za 0,99% ceny auta przy zamówieniu i rejestracji auta do 30.09.2024.-->
<!--            <br />-->
<!--            Ubezpieczenie OC/AC/NNW/HCA Premium dla pojazdów w pierwszym roku eksploatacji. Stała suma ubezpieczenia fabrycznie nowego pojazdu przez okres 12 miesięcy, brak konsumpcji sumy ubezpieczenia oraz udziału własnego w przypadku wystąpienia szkody, zniesiona zwyżka w przypadku pojazdów będących własnością (lub współwłasnością) osób fizycznych w wieku do 29 lat, naprawa samochodu w Autoryzowanym Serwisie Obsługi Fiat, z zastosowaniem części oryginalnych. Istnieje możliwość rozszerzenia ochrony o opcje dodatkowe - zwyżki z tytułu użytkowania oraz rozszerzenia ochrony dopłaca Klient zgodnie z tabelą zwyżek i zniżek. Oferta dotyczy tylko samochodów nowych.. Dotyczy tylko aut dostawczych z gamy PRE-MCA z wyjątkiem modelu Panda Van oraz Fiorino. Ubezpieczenie możliwe jest tylko w przypadku auta finansowanego przez Stellantis Financial Services i nie łączy się z dodatkowym rabatem 2,5%. Szczegóły dotyczące promocji dostępne w salonach Fiat.-->
<!--          </p>-->
          <p *ngIf="brand && brand.slug === 'alfa-romeo' && promoAR_oc_1">
            Ubezpieczenie OC/AC/NNW/Assistance dla pojazdów w pierwszym roku eksploatacji. Stała suma ubezpieczenia fabrycznie nowego pojazdu przez okres 12 miesięcy, brak konsumpcji sumy ubezpieczenia oraz udziału własnego w przypadku wystąpienia szkody, naprawa samochodu w Autoryzowanym Serwisie Obsługi Alfa Romeo, z zastosowaniem części oryginalnych. Pakiet ubezpieczeń dostępny za 1% w przypadku skorzystania przez Klienta z oferty zamówienia online. Istnieje możliwość rozszerzenia ochrony o opcje dodatkowe - zwyżki z tytułu użytkowania oraz rozszerzenia ochrony dopłaca Klient zgodnie z tabelą zwyżek i zniżek. Oferta dotyczy tylko samochodów nowych. Promocją objęte jest pierwszych {{ bannerCounter.totalOffersCount }} zamówień online. Licznik na stronie salon.alfaromeo.pl wyznacza ilość dostępnych samochodów. Promocja trwa do {{dateInNote}} lub do wyczerpania zapasów  Szczegóły dotyczące promocji dostępne w salonach Alfa Romeo.
          </p>
          <p *ngIf="brand && brand.slug === 'alfa-romeo' && !classReference.bodyClass.includes('page-offert') && !classReference.bodyClass.includes('sol-cart')">
            Pakiet dodatkowej ochrony serwisowej dotyczy wyłącznie samochodów Alfa Romeo Junior, Tonale, Giulia, Stelvio sprzedanych lub zamówionych w grudniu 2024 i obejmuje 2 lata gwarancji umownej i 1 rok dodatkowej ochrony pogwarancyjnej do całkowitego limitu przebiegu 120.000 km. To świadczenie jest realizowane poprzez umowę Przedłużonej Ochrony - szczegóły tego pakietu dostępne są u autoryzowanych dealerów Alfa Romeo.
          </p>
          <p *ngIf="brand && brand.slug === 'alfa-romeo' && classReference.bodyClass.includes('page-offerts-list')">
            Pakiet dodatkowej ochrony serwisowej dotyczy wyłącznie samochodów Alfa Romeo Junior, Tonale, Giulia, Stelvio sprzedanych lub zamówionych w grudniu 2024 i obejmuje 2 lata gwarancji umownej i 1 rok dodatkowej ochrony pogwarancyjnej do całkowitego limitu przebiegu 120.000 km. To świadczenie jest realizowane poprzez umowę Przedłużonej Ochrony - szczegóły tego pakietu dostępne są u autoryzowanych dealerów Alfa Romeo.
          </p>
          <p *ngIf="brand && brand.slug !== 'alfa-romeo'">Niniejszy materiał nie stanowi oferty w rozumieniu przepisów Kodeksu cywilnego i ma charakter informacyjny. Prezentacja pojazdu na stronie internetowej ma charakter poglądowy, rzeczywisty wygląd i cechy pojazdu oraz dostępne opcje wyposażenia mogą się różnić od prezentowanych na stronie internetowej.</p>
          <p *ngIf="brand && brand.slug !== 'jeep' && brand.slug !== 'alfa-romeo'">Szczegółowe informacje, w tym informacje o aktualności ogłoszenia i dostępności pojazdu, należy uzyskać u dealera. Sprzedający nie odpowiada za ewentualne błędy w ogłoszeniu.</p>
          <p *ngIf="brand && (brand.slug === 'jeep' || brand.slug === 'alfa-romeo')">Niniejsze ogłoszenie jest wyłącznie informacją handlową i nie stanowi oferty w myśl art. 66, § 1. Kodeksu
            Cywilnego.<br/>Sprzedający nie odpowiada za ewentualne błędy lub nieaktualność ogłoszenia.</p>
          <p *ngIf="brand && (brand.slug === 'jeep' || brand.slug === 'alfa-romeo')">
            Podane ceny obejmują VAT. Wszystkie ceny są traktowane jako sugestie FCA Poland Sp. z o.o. dla dealerów i są to ceny orientacyjne wyliczone dla pojazdów wyprodukowanych. Do podanych cen doliczana będzie opłata transportowa o wysokości 500 zł brutto. Naszym zamiarem jest pokazywanie zawsze jak najbardziej aktualnych cen. Jednak z uwagi czynniki na które producent pojazdu ani FCA Poland Sp. z o.o. nie ma wpływu i nie ponosi za nie odpowiedzialności, producent pojazdu ani FCA Poland nie może zagwarantować ceny pojazdu ani też daty jego produkcji. Montaż akcesoriów dodatkowych może wymagać dodatkowej opłaty. Szczegóły w salonach
          </p>
          <p *ngIf="brand && brand.slug !== 'abarth'">
            Warunkiem zawarcia umowy rezerwacyjnej jest potwierdzenie warunków Zamówienia (w tym m.in. dostępności i wyposażenia pojazdu) przez Dealera.
          </p>
        </div>
      </div>
    </div>
  </section>

  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 text-center text-lg-left">
          <img [lazyLoad]="'assets/img/logo_stellantis_financial_blue.svg'"
               class="footer__logo-stellantis-financial" alt="logo Stellantis Financial"/>
        </div>
        <div class="col-lg-3">
          <div class="float-lg-right">
            <div class="watch-us">
              <h4 class="watch-us__headline" *ngIf="brand && brand.slug === 'alfa-romeo'">Obserwuj nas</h4>
              <ul class="social-list" *ngIf="brandConfig && brand && brand.slug !== 'fiat' && brand.slug !== 'fiat-professional'">
                <li class="social-item" *ngIf="brandConfig.externalAssets.Facebook">
                  <a [href]="brandConfig.externalAssets.Facebook" target="_blank" class="social-link"><i
                    class="icon-facebook"></i></a>
                </li>
                <li class="social-item" *ngIf="brandConfig.externalAssets.Twitter">
                  <a [href]="brandConfig.externalAssets.Twitter" target="_blank" class="social-link icon-twitter-svg">
                    <svg data-v-7a16bb9b="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="inherit" viewBox="0 0 16 16">
                      <path data-v-7a16bb9b="" d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"></path>
                    </svg>
                  </a>
                </li>
                <li class="social-item" *ngIf="brandConfig.externalAssets.YouTube">
                  <a [href]="brandConfig.externalAssets.YouTube" target="_blank" class="social-link"><i
                    class="icon-youtube-play"></i></a>
                </li>
                <li class="social-item" *ngIf="brandConfig.externalAssets.Instagram">
                  <a [href]="brandConfig.externalAssets.Instagram" target="_blank" class="social-link"><i
                    class="icon-instagram"></i></a>
                </li>
                <li class="social-item" *ngIf="brandConfig.externalAssets.Linkedin">
                  <a [href]="brandConfig.externalAssets.Linkedin" target="_blank" class="social-link"><i
                    class="icon-linkedin"></i></a>
                </li>
              </ul>
              <ul class="social-list" *ngIf="brandConfig && brand && (brand.slug === 'fiat' || brand.slug === 'fiat-professional')">
                <li class="social-item" *ngIf="brandConfig.externalAssets.Facebook">
                  <a [href]="brandConfig.externalAssets.Facebook" target="_blank" class="social-link social-link-facebook">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.0009 8.04921C16.0009 3.60375 12.419 0 8.00047 0C3.58193 0 0 3.60375 0 8.04921C0 12.0668 2.92566 15.3968 6.7504 16.0006V10.3759H4.71903V8.04921H6.7504V6.27587C6.7504 4.25853 7.94481 3.14422 9.77229 3.14422C10.6476 3.14422 11.5632 3.30143 11.5632 3.30143V5.28229H10.5543C9.56048 5.28229 9.25054 5.90276 9.25054 6.5393V8.04921H11.4694L11.1147 10.3759H9.25054V16.0006C13.0753 15.3968 16.0009 12.0668 16.0009 8.04921Z" fill="#1B1C1B"/>
                    </svg>
                  </a>
                </li>
                <li class="social-item" *ngIf="brandConfig.externalAssets.Instagram">
                  <a [href]="brandConfig.externalAssets.Instagram" target="_blank" class="social-link social-link-instagram"><i
                    class="icon-instagram"></i></a>
                </li>
                <li class="social-item" *ngIf="brandConfig.externalAssets.YouTube">
                  <a [href]="brandConfig.externalAssets.YouTube" target="_blank" class="social-link social-link-youtube"><i
                    class="icon-youtube-play"></i></a>
                </li>
                <li class="social-item" *ngIf="brandConfig.externalAssets.Twitter">
                  <a [href]="brandConfig.externalAssets.Twitter" target="_blank" class="social-link icon-twitter-svg">
                    <svg data-v-7a16bb9b="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="inherit" viewBox="0 0 16 16">
                      <path data-v-7a16bb9b="" d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"></path>
                    </svg>
                  </a>
                </li>
                <li class="social-item" *ngIf="brandConfig.externalAssets.Linkedin">
                  <a [href]="brandConfig.externalAssets.Linkedin" target="_blank" class="social-link social-link-linkedin">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 1.14614C0 0.513313 0.526012 0 1.17491 0H14.8251C15.474 0 16 0.513315 16 1.14614V14.8539C16 15.4869 15.474 16 14.8251 16L1.17491 16C0.526012 16 0 15.4869 0 14.8539V1.14614ZM4.94338 13.3937V6.16876H2.54196V13.3937H4.94338ZM3.74266 5.18232C4.58008 5.18232 5.10131 4.62753 5.10131 3.9342C5.0857 3.22527 4.58008 2.68587 3.75854 2.68587C2.93709 2.68587 2.4 3.22527 2.4 3.9342C2.4 4.62753 2.92112 5.18232 3.72701 5.18232H3.74266ZM8.6514 13.3937V9.35897C8.6514 9.14304 8.667 8.92732 8.73042 8.77296C8.90401 8.34153 9.29915 7.89471 9.96255 7.89471C10.8315 7.89471 11.1791 8.55725 11.1791 9.52851V13.3937H13.5803V9.251C13.5803 7.03184 12.3956 5.99922 10.8156 5.99922C9.54177 5.99922 8.9706 6.69937 8.65146 7.19167V7.21656H8.63543C8.6407 7.20832 8.64605 7.20003 8.65146 7.19167V6.16876H6.25007C6.28158 6.8467 6.25007 13.3937 6.25007 13.3937H8.6514Z" fill="#1B1C1B"/>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5">
          <ul class="nav" *ngIf="brandConfig">
            <li class="nav-item" *ngIf="brandConfig.infoLineNumber && brandConfig.infoLineNumber !== null">
              <span>infolinia {{brandConfig.infoLineNumber}}</span>
            </li>
            <li class="nav-item" *ngIf="brandConfig.contactFormUrl !== null">
              <a class="nav-link" target="_blan" [href]="brandConfig.contactFormUrl">Kontakt</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" target="_blan" [href]="footerLinkToBrand">{{footerLinkToBrand.split('//')[1]}}</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-7">
          <ul class="nav float-lg-right">
            <li class="nav-item">
              <span>&copy; FCA POLAND 2024</span>
            </li>
            <li class="nav-item d-none">
              <a class="nav-link" href="#">Nota prawna</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="openPrivacyNotes()">Polityka prywatności</a>
            </li>
            <li class="nav-item">
              <a target="_self" class="nav-link cl-policy-link" href="javascript:getCookieOpenModal()" rel="nofollow" data-adobe="bottom-nav:editorial:cookies">Cookie</a>
            </li>
            <li class="nav-item" *ngIf="classReference.isDealerStock === false">
              <a class="nav-link" [routerLink]="'/dealerzy' | addUrlPrefix">Lista dealerów</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
  <div class="cover-active-field"></div>
  <ng-container *ngIf="debuggerEnabled">
    <div class="d-none">
      <ngx-json-viewer [json]="{}"></ngx-json-viewer>
    </div>
    <hr />
    <div class="row mb-3 ml-0 w-100 bg-white">
      <div class="col-11">
        <button class="btn btn-sm btn-secondary mr-3" (click)="trackingDebuggerEmitter.emit(true)">Tracking debugger</button>
      </div>
      <div class="col-1 text-right">
        <button class="btn btn-sm btn-outline-warning" (click)="closeDebugger()">Close</button>
      </div>
    </div>
  </ng-container>
<!--  <livechat-widget-->
<!--    *ngIf="getDealerLiveChatGroup(classReference.dealerCode) !== false"-->
<!--    [licenseId]="getLiveChatLicense()"-->
<!--    [group]="getDealerLiveChatGroup(classReference.dealerCode)"></livechat-widget>-->
  <app-tracking-debugger *ngIf="debuggerEnabled" [open]="trackingDebuggerEmitter"></app-tracking-debugger>
</div>
