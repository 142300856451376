let version = '0';

// get build hash from styles filename - add this to assets
if ($('link').length > 0) {
  const name = $($('link')[0]).attr('href');
  const start = name.indexOf('.') + 1;
  const end = name.indexOf('.', start + 1);

  if (start !== -1 && end !== -1) {
    version = name.substr(start, end - start);
  }
}
export const environment = {
  production: true,
  version: version.toString(),
  APIBaseURL: '/api/',
  CDNBaseURL: 'https://cdn.fcapoland.pl',
  multiBrand: false,
  financialCalculations: {
    apiUrl: 'https://sfs.fcapoland.pl/',
    wsProtocol: 'wss://',
    wsHost: 'sfs.fcapoland.pl',
    wsPort: 443,
    wsPath: '/ws',
    wsSupport: true,
    isProduction: true,
    isDebugMode: false,
  },
  isSol: true,
};
